.topbar{
    padding: 15px;
  
  
}
.topbar>h6
{
    font-size: 14px;
    color:#707070;
    font-family: sans-serif;
    font-weight: 400;
}
.nav_item
{
    margin: 2px 15px;
    background-size: 2%;
    transition: ease-in-out 0.5s;

}
.nav_item>a
{
    font-family: sans-serif;
    
}
.nav_item>a:hover
{
    background-size:100%;
    outline: none;
    text-decoration: none;
    background-color: #9b897e;
    color: white !important;
    border-radius: 3px;
    font-weight: bold;
}
.nav-logo{
    height:130px ;
    object-fit: contain;
   

}
.dropdown_menu_container
{
    display: flex !important;
    flex-wrap:wrap;
    overflow: hidden;
   
    

}
.dropdown_item_container
{
    display: flex;
    flex-direction: column;
    padding: 15px;
    border-radius: 3px;
    margin:10px 10px ;
    background-color: white;
    justify-content: space-around;
    align-items: center;
    cursor: pointer;
    width: fit-content;
}
.dropdown_item_container>h5
{
    font-size: 20px;
    font-weight: 700;
    font-family: sans-serif;
    padding: 5px;
    text-decoration: none;
    transition: ease-in-out 1s;

}
.nav_dropdown_img
{
    height: 130px;
    object-fit: contain;
    border-bottom: 1px solid lightgray;
}
.dropdown_item_container:hover>h5
{
    text-decoration: underline;
}
.dropdown_menu { 
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15)!important; 
    margin-top:0px !important; 
    width:100% !important; 
} 

.drop_down { 
	position:static !important; 
    
} 

