
.Catalog_img_container
{
    width:100%;
    padding:10px;
    border-radius: 3px;
    transition: ease-in-out 1s;
    cursor: pointer;
    display: flex ;
    flex-direction: column;
}

.Catalog_img_container:hover>img
{
    transform: scale(110%);

}
.Catalog_img_container>div
{
    margin-top: -70px;
    font-size: 30px;
    z-index: 100;
    width: 100%;
    padding: 0px;
    transition: ease-in-out 0.5s;
    text-align: center;
   }
   .Catalog_img_container>div>h5
{
   
    font-size: 30px;
    z-index: 100;
    width: 100%;
    transition: ease-in-out 0.5s;
    text-align: center;
    margin: 0px;
   }
   .Catalog_img_container>div>h6
   {
       font-size: 20px;
       z-index: 100;
       width: 100%;
       transition: ease-in-out 0.5s;
       text-align: center;
      }
.Catalog_img_container:hover>div>h5
{
    background-color: rgba(240, 248, 255,0.5);
    width: 100%;
}
.Catalog_img_container:hover>div>h6
{
    font-weight: bold;
    background-color: rgba(240, 248, 255,0.5);
    width: 100%;
}
.catalog_content_container
{
    padding: 15px;
    display: flex;
    margin-top: 10px;
    height: 100%;
    align-content: center;
    flex-direction: column;
    align-items: center;
}
.catalog_content_container>h5
{
    font-size: 40px;
    width: 100%;
    text-align: start;
    padding: 20px;
    font-weight: 700;
    margin-top: 15px;
}
.catalog_content_container>p
{
    font-size: 24px;
    font-family: sans-serif;
    width: 100%;
    text-align: start;
    padding: 20px;
    font-weight: 400;
    margin-top: 5px;
}
