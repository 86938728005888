.footer_logo
{
    height: 150px;
    object-fit: contain;
    border-radius: 2px;
    padding: 5px;
}
.footer_title
{
    font-size: 16px;
    font-weight: 600;
    font-family: sans-serif;
    padding: 10px 0px;
}
.footer_contact_container>h6
{
    font-size: 13px;
    font-family: sans-serif;
    font-weight: 500;
}
.social_media_container
{
    display:flex;
    padding: 10px 0px;
    margin-top: 20px;
    justify-content: flex-start;

}

.social_media_icon_container
{
    margin: 2px 10px;
    cursor: pointer;
    color: black !important;
    padding: 3px;
    transition: ease-in-out 0.5s;
}
.social_media_icon_container:hover
{
    background-color: white;
    border: 0.1px solid #F6b024;
    color:#2998ED !important;
    border-radius: 50%;
    font-size: large;
}
.useful_link_container
{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 15px;
}
.useful_link
{
    transition: ease-in-out 0.5s;
    cursor: pointer;
    text-decoration: none;
    color: black;
}
.useful_link>h6:hover
{
    color: #9b897e;
    font-weight: 700;
    font-size: 18px;
}
.footer_company_intro>p{
    color:gray;
    font-family: sans-serif;
    font-size: 14px;

}
.site_ownership_container
{
    display: flex;
    flex-direction: column;
    align-items: center;

}
.site_ownership_container>h5,h6
{
    font-size: 14px;
    font-weight: 500;
    font-family: sans-serif;

}
.site_ownership_container>h6>span
{
    font-weight: 700;
}

