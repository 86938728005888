.About_us_container
{
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.About_us_container>h5
{
    font-size: 40px;
    width: 100%;
    text-align: start;
    padding: 20px;
    font-weight: 700;
    margin-top: 15px;
}
.About_us_container>p
{
    font-size: 24px;
    font-family: sans-serif;
    width: 100%;
    text-align: start;
    padding: 20px;
    font-weight: 400;
    margin-top: 5px;
}
.goto__top
{
   padding: 5px;
   position: fixed;
   bottom: 40px;
 
   width: 100%;
    justify-content: flex-end;
   border-radius: 3px;
   float: right;
   display: flex;
   align-self: flex-end;
   color: white !important;
}
.goto__top>div
{
    padding: 5px;
   position: fixed;
   bottom: 20px;
   border-radius: 3px;
   margin-right: 5px;
   background-color:  #9b897e; 
}
.goto__top>div>a
{
    text-decoration: none;
    color: white;
}
.about_us_page_title_container
{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #fbf7f4;
}
.aboutuspage_content_container
{
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: flex-start;
 padding: 10px;
 height: 100%;

}
.aboutuspage_content_container>h6
{
    color:#815d47;
    font-weight: 700;
    font-size: 20px;
    font-family: sans-serif;
    margin: 15px 0px;
}
.aboutuspage_content_container>h3
{
    color:black;
    font-weight: 700;
    font-size: 30px;
    font-family: sans-serif;
    margin: 15px 0px;
}
.aboutuspage_content_container>p
{
    color:gray;
    font-weight: 500;
    font-size: 16px;
    margin: 15px 0px;
    font-family: sans-serif;
    line-height: 30px;
}
.about_us_page_title_container>p>span
{
    color:gray;
    font-weight: 800;
    font-size: 18px;
    margin: 15px 0px;
    font-family: sans-serif;
    line-height: 30px;
}
.read_more_button
{
    background-color:#815d47;
    color:white;
    border-radius: 3px;
    border: none;
    font-family: sans-serif;
    padding: 10px;
    transition: ease-in-out 0.5s;
}
.read_more_button:hover
{
    font-family: sans-serif;
    font-weight: 700;
    padding: 15px;
}