.catagory_description
{
    display: flex;
    flex-direction: column;
    padding: 10px;
   
 
}
.catagory_description>h6
{
    font-size: 30px;
    font-family: sans-serif;
    font-weight: 700;
}
.catagory_description>p
{
    font-size: 17px;
    font-family: sans-serif;
    font-weight: 500;
    margin-top: 20px;
    color: #54504f;
}
