.catagory_item_container
{
    display: flex;
    flex-direction: column;
    padding: 10px;
    cursor: pointer;
    border:0.1px solid lightgray ;
    border-radius: 3px;

    

}
.catagory_img_below
{
  
   
    opacity: 0;
    height: 250px;
    border-radius: 5px;
    object-fit: contain;
    transition: ease-in-out 1s;
    display: none;

}
.catagory_img_above
{
    opacity: 1;
    transition: ease-in-out 1s;
    height: 250px;
    border-radius: 5px;
    object-fit: contain;
    display: block;
}
.catagory_item_container:hover>.catagory_img_above
{
    opacity: 0;
    display: none;
}
.catagory_item_container:hover>.catagory_img_below
{
    opacity: 1;
    display: block;
}
.catagory_item_container>h6
{
    font-size: 18px;
    font-weight: 600;
    font-family: sans-serif;
    text-align: center;
    width: 100%;
    margin-top: 10px;
}