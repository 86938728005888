.item_detail_container
{
    display: flex;
    flex-direction: column;
    padding: 5px;
    justify-content: flex-start;
   
}
.item_title
{
    font-family: sans-serif;
    font-weight: 700;
}
.item_detail_container>h6
{
    font-size: 14px;
    padding: 2;
    font-weight: 500;
    font-family: sans-serif;
    color :#7f757d;
   
}
.item_detail_container>h6>span
{
    font-size: 17px;
    font-weight: 500;
    font-family: sans-serif;
    color :black;
}
.item_size_container
{
    display: flex;
    padding: 0px 15px;
    flex-direction: column;
    justify-content: flex-start;
    
}
.item_size_container>div
{
    padding: 5px;
    margin: 3px 0px;
    display: flex;
    justify-content: center;
    border: 0.1px solid lightgray;
}
.item_size_container>div>h6
{
    font-weight: 500;
    font-family: sans-serif;
    font-size: 14px;
    color:rgb(192, 192, 192)
}
.default_text_size{
    font-size: 14px;
    font-family: sans-serif;
    font-weight: 500;   
}
.price_container
{
    display: flex;
    margin-top: 15px;
    justify-content: flex-start;
    width: 100%;
    max-width: auto;
   
}
.price_container>span
{
    font-weight: 700;
    width: 100%;
    font-size: 17px;
}
.material_container
{
    display: flex;
    align-items: center;
    margin: 15px 0px;
    justify-content: flex-start;
}
.material_container>h6
{
    font-size: 14px;
    font-weight: 500;
    font-weight: 500;
    margin-right: 20px;
    color :#7f757d;
}