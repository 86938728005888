.featured_container
{
    width:100%;
    padding:1px;
    
    border-radius: 3px;
    transition: ease-in-out 1s;
    cursor: pointer;
    display: flex ;
   
    flex-direction: column;
}
.featured_container>.card:hover
{
    transform: scale(113%);
    background-color: white!important;
}
.card-title
{
    position: absolute !important;
    bottom: 0 !important;
    width: 95% !important;
    text-align: center;
}