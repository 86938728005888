.carousel_container
{
    background-image:url("/public/Assets/background\ image.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 10px;
    
    position: relative;
    display: flex;
    padding: 0px;
    
    justify-content: center;
    align-items: center;
    
}
.carousel_container>div
{
 
    width: 100%;
    height: 100%;
    padding: 100px 5px;
    
}
.carousel_item
{
    height: auto;
  
    flex-direction: column;
    align-items: center;
    padding: 50px 0px;
    justify-content: center;
    align-items: center;
    width: 80%;
    margin: auto auto;
  
}
.carousel_item>div
{
  
    width: 100%;
    color: white;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}